import api from '@/helpers/api'
import companyHelpers from '@/helpers/company'
import paymentHelpers from '@/helpers/payment'
import helpers from '@/helpers'
import i18n from '@/plugins/i18n'
import store from '@/store'

export default {
  async fetchUserInfo() {
    // redirect to home page if no user found
    let userId = ''
    try {
      userId = helpers.getTokenInfo().uid
    } catch (exception) {
      window.location.href = '/'
    }

    store.dispatch('setSystemMessage', {
      message: `${i18n.t('loading_user')}...`,
      type: 'success'
    })

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/users/${userId}`
    }

    try {
      const response = await api.promise(apiSettings)
      const user = response

      store.dispatch('setUserData', user)
      store.dispatch('setSystemMessage', null)

      return user
    } catch (error) {
      // company not found means user is in setup stage
      store.dispatch('setCompanyData', null)
    }
  },

  async fetchUserCompany(userId) {
    if (!userId) {
      return null
    }

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/users/${userId}/companies`
    }

    try {
      const response = await api.promise(apiSettings)
      // @todo: temporary take the first company of the user, before company switching feature is done
      const company = response.companies[0]
      store.dispatch('setCompanyData', company)
      store.dispatch('setSystemMessage', null)

      return company
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },

  async fetchCurrentUserCompany(userId) {
    if (!userId) {
      return null
    }

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/users/${userId}/companies`
    }

    try {
      const response = await api.promise(apiSettings)

      const curCompanyId = store.state.currentCompanyId

      var company = response.companies.find((item) => item.id === curCompanyId)
      if (!company) {
        // if not found or curCompanyId is null, get the first
        company = response.companies[0]
      }

      // @todo: if id exists -> take
      // otherwise, check admin role -> open companies
      // otherwise, if not -> take the first

      store.dispatch('setCompanyData', company)
      store.dispatch('setSystemMessage', null)

      return company
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },

  async fetchAllUserCompanies(userId) {
    if (!userId) {
      return null
    }

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/users/${userId}/companies`
    }

    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)

      return response.companies
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },

  /**
   * Fetch user profile in the company, which is used to get the role of the user in the company
   */
  async fetchUserProfileInCompany(userId, companyId) {
    if (!userId || !companyId) {
      return null
    }

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/users/${userId}/companies/${companyId}/profile`
    }

    try {
      const response = await api.promise(apiSettings)
      const userProfile = response.profiles[0]

      store.dispatch('setSystemMessage', null)
      return userProfile
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },

  async fetchUserProfilesInCompany(userId, companyId) {
    if (!userId || !companyId) {
      return null
    }

    const profilesFromStorage = store.state.companyProfiles[companyId]

    if (profilesFromStorage) {
      return profilesFromStorage
    }

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/users/${userId}/companies/${companyId}/profile`
    }

    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)
      store.dispatch('setCompanyProfiles', { company_id: companyId, profiles: response.profiles })

      return response.profiles
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },

  /**
   * Check if user has the specified role in the company
   */
  async checkUserRoleInCompany(userId, companyId, roleName) {
    const profiles = await this.fetchUserProfilesInCompany(userId, companyId);
  
    if (!profiles.length) {
      return false;
    }
  
    const roles = profiles.flatMap((profile) => profile.role);
  
    return roleName === 'company_admin'
      ? roles.some((role) => role.role_name === roleName) || companyHelpers.isEnterpriseAdminInCurrentCompany() || roles.some((role) => role.role_name === 'role_parkman_admin') || roles.some((role) => role.role_name === 'parkman_admin')
      : roles.some((role) => role.role_name === roleName);
  },

  /**
   * Edit user information
   * @param {Integer} userId
   * @param {Object} data - edited data of the user
   */
  async editUser(userId, data) {
    const apiSettings = {
      method: 'put',
      service: 'users',
      url: `v1/users/${userId}`,
      data: data
    }

    try {
      await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)
    } catch (error) {
      throw new Error(error[0].message)
    }
  },

  /**
   * Fetch the list of groups that user belong to
   */
  async fetchUserGroups(userId) {
    if (!userId) {
      return null
    }

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/users/${userId}/groups`
    }

    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)

      return response.groups
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },

  /**
   * Fetch the list of company employee
   */
  async fetchGroupEmployees(groupId) {
    if (!groupId) {
      return
    }

    store.dispatch('setSystemMessage', {
      message: `${i18n.t('loading_employee')}...`,
      type: 'success'
    })

    const apiSettings = {
      employees: {
        method: 'get',
        service: 'users',
        url: `v1/groups/${groupId}/users`
      },
      invitation: {
        method: 'get',
        service: 'users',
        url: `v1/groups/${groupId}/invitations`
      }
    }

    try {
      const [employeesResponse, invitationsResponse] = await Promise.all([
        api.promise(apiSettings.employees),
        api.promise(apiSettings.invitation)
      ])

      const employees = employeesResponse.users
      const invitations = invitationsResponse.invitations

      const result = []

      // get list of group, to "translate" from group id to name
      const groupList = store.state.groupData

      employees.forEach((employee) => {
        // filter by group id
        // invitation-accepted user
        employee.is_pending = false
        employee.name =
          `${employee.first_name || ''} ${employee.last_name || ''}`.trim()
        employee.group_name = companyHelpers.getGroupNameById(
          groupId,
          groupList
        )
        result.push(employee)
      })

      if (employees.length > 0) {
        // fetch employee pricing package before handling invitation
        const employeeProfileIds = result.map((item) => item.profile_id)
        const employeePackages =
          await paymentHelpers.fetchEmployeePackageList(employeeProfileIds)
        result.forEach((employee) => {
          // add subscription to employee object after fetching
          const subscription = employeePackages.find(
            (item) => item.profile_id === employee.profile_id
          )
          employee.subscription = subscription
          employee.account_package_id = subscription
            ? subscription.account_package_id
            : null
        })
      }

      invitations.forEach((invitation) => {
        // skip displaying invitations with status:
        // `linked` - the user already accepted and created an account based on this invitation
        // `rejected` - the user rejected the invitation
        const hiddenStatuses = ['linked', 'reject']
        if (hiddenStatuses.indexOf(invitation.status) > -1) {
          return
        }

        invitation.is_pending = true
        // this did not returned from backend
        invitation.group_name = companyHelpers.getGroupNameById(
          groupId,
          groupList
        )
        if (invitation.identifier_type === 'email') {
          invitation.email = invitation.identifier
        }
        invitation.plan = '-'
        result.push(invitation)
      })

      store.dispatch('setEmployeeData', result)
      store.dispatch('setMaxTotalEmployeesAmount', result.length)
      store.dispatch('setSystemMessage', null)

      return result
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Fetch all system-supported roles for user
   */
  async fetchRoleOptions() {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('loading_role')}...`,
      type: 'success'
    })

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: 'v1/roles'
    }

    try {
      const response = await api.promise(apiSettings)
      const roles = response.roles

      store.dispatch('setRoleOptions', roles)
      store.dispatch('setSystemMessage', null)

      return roles
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Get driver role id
   */
  getDriverRoleId() {
    const roleOptions = store.state.roleOptions
    const role = roleOptions.find((item) => item.name === 'driver')
    return role.id
  },

  /**
   * Get company admin role id
   */
  getCompanyAdminRoleId() {
    const roleOptions = store.state.roleOptions
    const role = roleOptions.find((item) => item.name === 'company_admin')
    return role.id
  },

  /**
   * Get role name by its id
   * @param {Integer} role id
   * return Driver by default
   */
  getRoleTextById(roleId) {
    const defaultValue = i18n.t('role_driver')
    if (!roleId) {
      return defaultValue
    }

    const roleOptions = store.state.roleOptions
    if (!roleOptions) {
      return defaultValue
    }

    const role = roleOptions.find((item) => item.id === roleId)

    return role ? i18n.t(`role_${role.name}`) : defaultValue
  },

  /**
   * Check if the employee will have a change in pricing in the next month
   */
  doesEmployeeHavePricingUpdate(employee) {
    if (!employee.subscription) {
      return false
    }

    const subscription = employee.subscription
    if (!subscription.next_account_package) {
      return false
    }

    // when the employee subscribes to the same package, check if package has incoming change
    if (
      subscription.next_account_package_id === subscription.account_package_id
    ) {
      return subscription.next_account_package.package.prices[0].pending_update
    }

    return true
  },

  // @todo (Hieu): fetch companies and check if have at least 1 company with role enterprise admin
  // if yes, store companies in store & return true
  // otherwise, return false
  
  async fetchCostCenters(companyId) {
    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/b2b/companies/${companyId}/cost_centers`
    }

    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)

      return response
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },
  async createCostCenters(companyId, data) {
    const apiSettings = {
      method: 'post',
      service: 'users',
      url: `v1/b2b/companies/${companyId}/cost_centers`,
      data: data
    }

    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)

      return response
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },
  async deleteCostCenters(companyId, costCenterId) {
    const apiSettings = {
      method: 'delete',
      service: 'users',
      url: `v1/b2b/companies/${companyId}/cost_centers/${costCenterId}`
    }

    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)

      return response
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },
  async updateMandatoryCommentToggle(companyId, data) {
    const apiSettings = {
      method: 'post',
      service: 'users',
      url: `v1/b2b/companies/${companyId}/mandatory_comment/toggle`,
      data: data
    }

    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)
      return response
    } catch (error) {
      throw new Error(error[0].message)
    }
  }
}
