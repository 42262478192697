<template>
  <v-app-bar
    class="pkmn-nav container"
    :class="{ 'pkmn-nav--signup': $route.path === '/setup' }"
    app
    flat
    height="42"
    :key="isLoggedIn"
  >
    <v-toolbar-title>
      <img class="nav__logo" src="@/assets/logo.png" @click="redirectToHome" />
    </v-toolbar-title>
    <v-toolbar-items>
      <div class="d-none d-md-flex">
        <v-menu close-on-click offset-y v-if="switchCompanyVisible">
          <template v-slot:activator="{ on }">
            <span class="nav__button nav__button--text" v-on="on"
              >{{ helpers.getCurrentCompanyName() }}
              <v-icon>mdi-menu-down</v-icon></span
            >
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in dropdownSwitchCompany"
              :key="index"
              @click="item.callback"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <span v-else class="nav__button nav__button--text">
          {{ helpers.getCurrentCompanyName() }}
        </span>
      </div>
    </v-toolbar-items>
    <v-spacer></v-spacer>

    <v-toolbar-items>
      <div v-if="isLoggedIn && routesLinkVisible" class="d-none d-md-flex">
        <router-link
          class="nav__button nav__button--text"
          to="/costcenters"
          @click.native="/* tracking code here */"
        >
          <span>{{
            $t('cost_centers_title')
          }}</span>
        </router-link>
        <router-link
          class="nav__button nav__button--text"
          to="/groups"
          @click.native="/* tracking code here */"
        >
          <span>{{
            $t(auth.isGroupAdmin() ? 'nav_billing' : 'nav_groups')
          }}</span>
        </router-link>
        <router-link
          class="nav__button nav__button--text"
          to="/employees"
          @click.native="/* tracking code here */"
        >
          <span>{{ $t('settings_team_title') }}</span>
        </router-link>
        <router-link
          class="nav__button nav__button--text"
          to="/vehicles"
          @click.native="/* tracking code here */"
        >
          <span>{{ $t('nav_settings_vehicles') }}</span>
        </router-link>
        <router-link
          class="nav__button nav__button--text"
          to="/account"
          @click.native="/* tracking code here */"
        >
          <span>{{ $t('nav_account') }}</span>
        </router-link>
        <router-link
          class="nav__button nav__button--text"
          to="/transactions"
          @click.native="
            helpers.sendTrackingEvent(
              'MAIN PAGE',
              'click_business_account_transactions'
            )
          "
        >
          <span>{{ $t('nav_transactions') }}</span>
        </router-link>
      </div>
      <v-overflow-btn
        class="nav__button nav__button--text nav__button--lang"
        :items="languages"
        v-model="$i18n.locale"
        @change="saveLanguage"
      ></v-overflow-btn>
      <div v-if="!isLoggedIn">
        <v-btn
          class="nav__button"
          color="primary"
          v-if="loginButtonVisible"
          @click="showLoginForm"
        >
          {{ $t('g_log_in') }}
        </v-btn>
        <v-btn
          class="nav__button"
          color="primary"
          v-else
          @click="showSignupForm"
        >
          {{ $t('g_sign_up') }}
        </v-btn>
      </div>
    </v-toolbar-items>

    <mobile-nav v-if="isLoggedIn && routesLinkVisible" />
  </v-app-bar>
</template>

<script>
import auth from '@/auth'
import helpers from '@/helpers'

import MobileNav from '@/components/MobileNav'

export default {
  name: 'TopNav',
  components: { MobileNav },
  data() {
    return {
      auth: auth,
      languages: this.$i18n.availableLocales,
      helpers: helpers,
      isLoggedIn: false,
      isRoutesVisible: true,
      dropdownSwitchCompany: [
        {
          text: this.$t('switch_company_button'),
          gtm: 'click_nav_switch_company',
          callback: () => {
            if (this.switchCompanyVisible) {
              this.goToPage('/companies')
            }
          }
        }
      ]
    }
  },
  computed: {
    loginButtonVisible() {
      if (this.isLoggedIn) {
        return false
      }

      return this.$route.name !== 'Login'
    },
    routesLinkVisible() {
      return this.$route.path !== '/setup' && this.$route.path !== '/companies'
    },
    switchCompanyVisible() {
      var companies = this.$store.state.userCompanies
      var companiesAmount = 0
      if (companies) {
        companiesAmount = companies.length
      }
      return (
        this.isLoggedIn &&
        helpers.hasCompanyWithEnterpriseAdminRole &&
        this.$route.path !== '/companies' &&
        companiesAmount > 1
      )
    }
  },
  watch: {
    /**
     * Re-check if the user has logged in every time the route changes
     */
    '$route.path'() {
      this.isLoggedIn = auth.loggedIn()
    }
  },
  methods: {
    showLoginForm() {
      this.goToPage('/login')
      helpers.sendTrackingEvent('LOG IN', 'click_go_to_login_page')
    },
    showSignupForm() {
      this.goToPage('/signup')
      helpers.sendTrackingEvent('LOG IN', 'click_business_account_sign_up')
    },
    saveLanguage(val) {
      helpers.saveStorage('lang', val)
      window.zE.setLocale(val)
    },
    redirectToHome() {
      // skip home direction on login page / signup flow
      const EXCLUDED_ROUTE_NAMES = ['Login', 'Signup', 'Setup']
      if (EXCLUDED_ROUTE_NAMES.includes(this.$route.name)) {
        return
      }

      this.goToPage('/home')
    },
    goToPage(path) {
      this.$router.push(path)
    }
  },
  mounted() {
    // after logging in - prevent menu disappear after hot-reload
    this.isLoggedIn = auth.loggedIn()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.pkmn-nav {
  @include set-container-width;
  height: 80px !important;
  background: #fff !important;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 66px !important;
  }
}

.nav__logo {
  cursor: pointer;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .pkmn-nav--signup {
    position: relative !important;
    top: -64px !important;
    padding: 12px 0 !important;
  }
}

// make sure total height of the navigation is 80px
$nav-height: 42px;
$nav-v-margin: 19px;

$nav-height-mobile: 28px;
$nav-v-margin-mobile: 12px;

.v-toolbar {
  padding: $nav-v-margin 12px;

  &:after {
    content: ' ';
    height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0.11);
    display: block;
    margin-top: $nav-v-margin;
  }
}

.v-toolbar__title,
.nav__logo {
  height: $nav-height;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-toolbar {
    padding: $nav-v-margin-mobile 12px;

    &:after {
      margin-top: $nav-v-margin-mobile;
    }
  }

  .v-toolbar__title,
  .nav__logo {
    height: $nav-height-mobile;
  }
}

.v-toolbar__title {
  overflow: visible;
}

.nav__button {
  @include button;
}

.nav__button--text {
  color: $text-color-dark !important;
  font-weight: 400;
  text-decoration: none;
  padding: 0 16px !important;

  span {
    padding: 1px 0;
  }

  & span:hover,
  &.router-link-active span {
    border-bottom: 2px solid $color-brand;
  }

  .v-input__slot {
    border: none;
  }
}
</style>

<style lang="scss">
.pkmn-nav .v-toolbar__content {
  padding: 0;
}

.nav__button--text.v-overflow-btn .v-select__slot {
  height: 42px;
}

.nav__button--text.v-overflow-btn .v-input__slot {
  border: none;
}

.nav__button.nav__button--lang {
  padding: 0 0 0 4px !important;
  width: 64px !important;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 16px;
  }

  .v-input__slot {
    box-shadow: none !important;
  }

  .v-input__append-inner {
    width: 0;
    margin-left: -32px !important;
  }
}

.v-autocomplete__content {
  text-transform: uppercase;
}
</style>
