<template>
  <v-dialog
    content-class="pkmn-menu-content"
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" class="pkmn-menu__icon-wrap hidden-md-and-up">
        <v-icon v-if="dialog">mdi-close</v-icon>
        <div v-else class="pkmn-menu__burger-wrap">
          <img src="@/assets/burger.svg" />
        </div>
      </div>
    </template>
    <v-card>
      <v-list>
        <v-list-item
          class="pkmn-menu__switch_company"
        >
          <v-menu close-on-click offset-y>
            <template v-slot:activator="{ on }">
              <span class="nav__button nav__button--text" v-on="on">
                <h1>
                  {{ helpers.getCurrentCompanyName() }}
                  <v-icon v-if="switchCompanyVisible">mdi-menu-down</v-icon>
                </h1>
              </span>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in dropdownSwitchCompany"
                :key="index"
                @click="item.callback"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>

        <v-list-item
          v-for="(item, index) in items.top"
          :key="index"
          @click="goToPage(item.redirect)"
        >
          <h2>{{ item.title }}</h2>
        </v-list-item>

        <v-list-item
          class="pkmn-menu__bottom"
          v-for="item in items.bottom"
          :key="item.title"
          @click="item.action"
        >
          <h2>{{ item.title }}</h2>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import auth from '@/auth'
import helpers from '@/helpers'

export default {
  name: 'MobileNav',
  data() {
    return {
      dialog: false,
      helpers: helpers,
      items: {
        top: [
          {
            title: this.$t(auth.isGroupAdmin() ? 'nav_billing' : 'nav_groups'),
            redirect: '/groups'
          },
          {
            title: this.$t('settings_team_title'),
            redirect: '/employees'
          },
          {
            title: this.$t('nav_settings_vehicles'),
            redirect: '/vehicles'
          },
          {
            title: this.$t('nav_account'),
            redirect: '/account'
          },
          {
            title: this.$t('nav_transactions'),
            redirect: '/transactions'
          }
        ],
        bottom: [{ title: this.$t('g_log_out'), action: this.logout }]
      },
      dropdownSwitchCompany: [
        {
          text: this.$t('switch_company_button'),
          gtm: 'click_nav_switch_company',
          callback: () => {
            this.goToPage('/companies')
          }
        }
      ],
      isLoggedIn: false
    }
  },
  methods: {
    goToPage(path) {
      // @todo: track data
      this.$router.push(path)
      this.dialog = false
    },
    logout() {
      helpers.sendTrackingEvent('MAIN PAGE', 'click_business_account_log_out')
      auth.logout()
    }
  },
  computed: {
    switchCompanyVisible() {
      var companies = this.$store.state.userCompanies
      return (
        this.isLoggedIn &&
        helpers.hasCompanyWithEnterpriseAdminRole &&
        this.$route.path !== '/companies' &&
        companies.length > 1
      )
    }
  },
  mounted() {
    // after logging in - prevent menu disappear after hot-reload
    this.isLoggedIn = auth.loggedIn()
  }
}
</script>

<style lang="scss">
@import '@/style/common';

.pkmn-menu-content.v-dialog--fullscreen {
  border-top: 1px solid $color-line;
  box-shadow: none;
  top: 80px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    top: 64px;
  }
}

.pkmn-menu-content .v-list {
  max-width: 320px;
  margin: 0 auto;
  padding: 16px 0;
}

.pkmn-menu-content .v-list-group__items > .v-list-item {
  padding-left: 48px !important;
}

.pkmn-menu__icon-wrap {
  width: 32px;
}

.pkmn-menu__burger-wrap {
  height: 42px;
  width: 32px;
  text-align: right;
  padding: 12px 0;
}

.pkmn-menu__bottom {
  margin-top: 64px;
}

.pkmn-menu__switch_company {
  margin-bottom: 32px;
}
</style>
